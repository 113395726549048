import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IPayment } from './payment.interface';



const initialState: IPayment = {
  client_secret: '',
  order_id: '',
  documentId: '',
  billing_address: undefined,
};

export const paymentSlice = createSlice({
  name: 'paymentAction',
  initialState,
  reducers: {
    setBillingAdress: (
      state: IPayment,
      action: PayloadAction<IPayment['billing_address']>
    ) => {
      state.billing_address = action.payload;
    },
    setClientSecret: (state: IPayment, action: PayloadAction<Omit<IPayment, 'billing_address'>>) => {
      state.client_secret = action.payload.client_secret;
      state.order_id = action.payload.order_id;
      state.documentId = action.payload.documentId;
    },
    clearPayment: (state: IPayment) => {
      state.client_secret = '';
      state.order_id = '';
      state.documentId = '';
      state.billing_address = undefined;
    },
  },
});

export const { setBillingAdress, setClientSecret, clearPayment } =
  paymentSlice.actions;

export const paymentAction = paymentSlice.actions;

export default paymentSlice.reducer;

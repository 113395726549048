import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import baseApi from '../services/api/baseQuery';
import CONSTANTS from '../constants';
import loginReducer from './login/loginSlice';
import cartReducer from './cart/cartSlice';
import paymentReducer from './payment/paymentSlice';

const persistConfig = {
  key: CONSTANTS.storage.persistConfigKey,
  storage,
  whitelist: [
    'userDetails',
    'cart',
    'payment',
  ],
};
const appReducer = combineReducers({
  userDetails: loginReducer,
  cart: cartReducer,
  payment: paymentReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state:any, action:any) => {
  if (action.type === 'loginAction/setLogoutAction') {
    storage.removeItem(`persist:${CONSTANTS.storage.persistConfigKey}`);
    window.localStorage.setItem(CONSTANTS.storage.localStorageUserData, '{}');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);

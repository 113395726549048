/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, lazy, Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Paper } from '@mui/material';


import { useDispatch } from 'react-redux';
import { useAppSelector } from './redux/reduxHooks';
import { userDataSelector } from './redux/login/login.selector';
import { Routes, Route, Navigate } from 'react-router-dom';

import SnakebarsProvider from './components/snakebarsProviders';
import { ganrateTheme } from './infrastructure/theme';
import Loader from './components/Loader';
import ErrorBoundary from './components/errorBounndery';


const Navigation = lazy(() =>
  import('./routes/navigation/navigation.component')
);
const Shop = lazy(() => import('./routes/shop/shop.component'));
const Checkout = lazy(() => import('./routes/checkout/checkout.component'));
const Home = lazy(() => import('./routes/home/home.component'));
const Authentication = lazy(() =>
  import('./routes/authentication/authentication.component')
);
const Address = lazy(() => import('./routes/address'));
const Payment = lazy(() => import('./routes/makePayment'));
const Confirmation = lazy(() => import('./routes/paymentConfermation'));

const App = () => {
  const userData = useAppSelector(userDataSelector);
  return (
    <ThemeProvider theme={ganrateTheme('light')}>
      <Paper sx={{ borderRadius: '0px', minHeight: '100vh' }}>
        <div className='pd-20'>
          <SnakebarsProvider>
            <ErrorBoundary>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path='/' element={<Navigation />}>
                    <Route index element={<Home />} />
                    <Route path='shop/*' element={<Shop />} />
                    <Route path='/auth' element={
                      userData?.accessToken ?
                        <Navigate to="/" replace /> :
                        <Authentication />
                    } />
                    <Route path='checkout' element={<Checkout />} />
                    <Route path='address' element={<Address />} />
                    <Route path='payment' element={<Payment />} />
                    <Route path='confirmation' element={<Confirmation />} />
                  </Route>
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </SnakebarsProvider>
        </div>
      </Paper>
    </ThemeProvider >
  );
};

export default App;

import React from "react";
import { createRoot  } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { store, persistor } from "./redux/store";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


import "./index.scss";

if (process.env.NODE_ENV !== 'development') {
  disableReactDevTools();
};

const rootElement = document.getElementById("root");

const root = createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
            <App />
        </HashRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// serviceWorkerRegistration.register();


export const versionNo = '1.5.9';

export const storage = {
  persistConfigKey: 'ecommerce',
  localStorageUserData: 'localStorageUserData',
};

export const urls = {
  index: '/',
  home: '/',
  login: '/auth',
};

export const themeTypes = {
  light: 'light',
  dark: 'dark',
};

export const statusCode = {
  ok: 200,
  badRequest: 400,
  notFound: 404,
  tokenExpired: 0,
  unAuthorizedUser: 401,
  requestTimeout: 502,
  newPasswordRequired: 402,
};


export const rtk = {
  keepUnusedDataLong: 1800,
  keepUnusedDataMid: 600,
  keepUnusedDataShort: 300,
  keepUnusedDataVeryShort: 30,
};

